import React from 'react';
import { createUseStyles } from 'react-jss';
import { WiThermometer, WiRaindrop, WiUmbrella } from 'react-icons/wi';
import { WeatherIcon } from '../../components/WeatherIcon';
import { Color } from '../../constants';
import { Card } from '../../components/Card/Card';

const useStyles = createUseStyles({
  card: {
    display: 'flex',
    width: '100%',
    padding: '1.5vw',
    background: Color.white,
  },
  cardTitle: {
    fontSize: 'clamp(1.5rem, 2vw, 16rem)',
    textAlign: 'left',
    padding: 10,
  },
  container: {
    display: 'flex',
    height: '100%',
    padding: '1.5vw',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem',
  },
  icon: {
    display: 'flex',
    height: 'clamp(1.2rem, 7vw, 16rem)',
    width: 'clamp(1.2rem, 7vw, 16rem)',
    alignSelf: 'center',
  },
  value: {
    color: Color.kpOrange,
    padding: 0,
    fontSize: 'clamp(0.5rem, 5vw, 16rem)',
  },
  unit: {
    alignSelf: 'center',
    position: 'relative',
    top: 'clamp(0vw, 1vw, 2vw)',
  },
});

const translate = (text: string) => {
  switch (text) {
    case 'Thunderstorm':
      return 'Gewitter';
    case 'Rain':
    case 'Drizzle':
      return 'Regen';
    case 'Snow':
      return 'Schnee';
    case 'Clouds':
    case 'Mist':
    case 'Smoke':
    case 'Haze':
    case 'Dust':
    case 'Fog':
    case 'Sand':
    case 'Ash':
    case 'Squall':
      return 'Wolkig';
    case 'Tornado':
      return 'Stürmisch';
    case 'Clear':
      return 'Sonnig';
    default:
      return 'Heiter';
  }
};
export type CurrentWeatherBasicData = {
  icon: string;
  text: string;
  temperature: number;
  humidity: number;
  precipitation: number;
};

type CurrentWeatherBasicProps = {
  currentWeatherBasicData: CurrentWeatherBasicData;
};

export const CurrentWeatherBasicCard: React.FC<CurrentWeatherBasicProps> = ({
  currentWeatherBasicData,
}) => {
  const classes = useStyles();
  const { icon, text, temperature, humidity, precipitation } =
    currentWeatherBasicData;
  return (
    <Card
      title="So ist das Wetter aktuell"
      className={classes.card}
      titleClassName={classes.cardTitle}
    >
      <div className={classes.container}>
        <div className={classes.item}>
          <WiThermometer className={classes.icon} />
          <div className={classes.value}>{Math.round(temperature)}</div>
          <div className={classes.unit}>{`°C`}</div>
        </div>
        <div className={classes.item}>
          <WeatherIcon type={icon} className={classes.icon} />
          <div className={classes.value}>{translate(text)}</div>
        </div>
        <div className={classes.item}>
          <WiUmbrella className={classes.icon} />
          <div className={classes.value}>
            {(precipitation * 100).toFixed(0)}
          </div>
          <div className={classes.unit}>{`%`}</div>
        </div>
        <div className={classes.item}>
          <WiRaindrop className={classes.icon} />
          <div className={classes.value}>{humidity}</div>
          <div className={classes.unit}>{`%`}</div>
        </div>
      </div>
    </Card>
  );
};
