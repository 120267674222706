import React from 'react';
import { IconBaseProps } from 'react-icons';
import {
  WiMoonAltNew,
  WiMoonAltWaxingCrescent1,
  WiMoonAltWaxingCrescent2,
  WiMoonAltWaxingCrescent3,
  WiMoonAltWaxingCrescent4,
  WiMoonAltWaxingCrescent5,
  WiMoonAltWaxingCrescent6,
  WiMoonAltFirstQuarter,
  WiMoonAltWaxingGibbous1,
  WiMoonAltWaxingGibbous2,
  WiMoonAltWaxingGibbous3,
  WiMoonAltWaxingGibbous4,
  WiMoonAltWaxingGibbous5,
  WiMoonAltWaxingGibbous6,
  WiMoonAltFull,
  WiMoonAltWaningGibbous1,
  WiMoonAltWaningGibbous2,
  WiMoonAltWaningGibbous3,
  WiMoonAltWaningGibbous4,
  WiMoonAltWaningGibbous5,
  WiMoonAltWaningGibbous6,
  WiMoonAltThirdQuarter,
  WiMoonAltWaningCrescent1,
  WiMoonAltWaningCrescent2,
  WiMoonAltWaningCrescent3,
  WiMoonAltWaningCrescent4,
  WiMoonAltWaningCrescent5,
  WiMoonAltWaningCrescent6,
  WiNa,
} from 'react-icons/wi';

type MoonIconProps = IconBaseProps & {
  moonPhase: number;
};

export const MoonIcon: React.FC<MoonIconProps> = ({
  moonPhase,
  ...iconProps
}) => {
  switch (true) {
    case moonPhase === 0: {
      // new moon
      return <WiMoonAltNew {...iconProps} />;
    }
    case moonPhase > 0 && moonPhase <= 0.04: {
      // waxing crescent
      return <WiMoonAltWaxingCrescent1 {...iconProps} />;
    }
    case moonPhase > 0.04 && moonPhase <= 0.08: {
      // waxing crescent
      return <WiMoonAltWaxingCrescent2 {...iconProps} />;
    }
    case moonPhase > 0.08 && moonPhase <= 0.12: {
      // waxing crescent
      return <WiMoonAltWaxingCrescent3 {...iconProps} />;
    }
    case moonPhase > 0.12 && moonPhase <= 0.16: {
      // waxing crescent
      return <WiMoonAltWaxingCrescent4 {...iconProps} />;
    }
    case moonPhase > 0.16 && moonPhase <= 0.2: {
      // waxing crescent
      return <WiMoonAltWaxingCrescent5 {...iconProps} />;
    }
    case moonPhase > 0.2 && moonPhase < 0.25: {
      // waxing crescent
      return <WiMoonAltWaxingCrescent6 {...iconProps} />;
    }
    case moonPhase === 0.25: {
      // first quarter moon
      return <WiMoonAltFirstQuarter {...iconProps} />;
    }
    case moonPhase > 0.26 && moonPhase <= 0.3: {
      // waxing gibbous
      return <WiMoonAltWaxingGibbous1 {...iconProps} />;
    }
    case moonPhase > 0.3 && moonPhase <= 0.34: {
      // waxing gibbous
      return <WiMoonAltWaxingGibbous2 {...iconProps} />;
    }
    case moonPhase > 0.34 && moonPhase <= 0.38: {
      // waxing gibbous
      return <WiMoonAltWaxingGibbous3 {...iconProps} />;
    }
    case moonPhase > 0.38 && moonPhase <= 0.42: {
      // waxing gibbous
      return <WiMoonAltWaxingGibbous4 {...iconProps} />;
    }
    case moonPhase > 0.42 && moonPhase <= 0.46: {
      // waxing gibbous
      return <WiMoonAltWaxingGibbous5 {...iconProps} />;
    }
    case moonPhase > 0.46 && moonPhase < 0.5: {
      // waxing gibbous
      return <WiMoonAltWaxingGibbous6 {...iconProps} />;
    }
    case moonPhase === 0.5: {
      // full moon
      return <WiMoonAltFull {...iconProps} />;
    }
    case moonPhase > 0.5 && moonPhase <= 0.54: {
      // waning gibbous
      return <WiMoonAltWaningGibbous1 {...iconProps} />;
    }
    case moonPhase > 0.54 && moonPhase <= 0.6: {
      // waning gibbous
      return <WiMoonAltWaningGibbous2 {...iconProps} />;
    }
    case moonPhase > 0.6 && moonPhase <= 0.64: {
      // waning gibbous
      return <WiMoonAltWaningGibbous3 {...iconProps} />;
    }
    case moonPhase > 0.64 && moonPhase <= 0.68: {
      // waning gibbous
      return <WiMoonAltWaningGibbous4 {...iconProps} />;
    }
    case moonPhase > 0.68 && moonPhase <= 0.72: {
      // waning gibbous
      return <WiMoonAltWaningGibbous5 {...iconProps} />;
    }
    case moonPhase > 0.72 && moonPhase < 0.75: {
      // waning gibbous
      return <WiMoonAltWaningGibbous6 {...iconProps} />;
    }
    case moonPhase === 0.75: {
      // last quarter moon
      return <WiMoonAltThirdQuarter {...iconProps} />;
    }
    case moonPhase > 0.75 && moonPhase <= 0.8: {
      // waning crescent
      return <WiMoonAltWaningCrescent1 {...iconProps} />;
    }
    case moonPhase > 0.8 && moonPhase <= 0.84: {
      // waning crescent
      return <WiMoonAltWaningCrescent2 {...iconProps} />;
    }
    case moonPhase > 0.84 && moonPhase <= 0.88: {
      // waning crescent
      return <WiMoonAltWaningCrescent3 {...iconProps} />;
    }
    case moonPhase > 0.88 && moonPhase <= 0.92: {
      // waning crescent
      return <WiMoonAltWaningCrescent4 {...iconProps} />;
    }
    case moonPhase > 0.92 && moonPhase <= 0.96: {
      // waning crescent
      return <WiMoonAltWaningCrescent5 {...iconProps} />;
    }
    case moonPhase > 0.96 && moonPhase < 1: {
      // waning crescent
      return <WiMoonAltWaningCrescent6 {...iconProps} />;
    }
    case moonPhase === 1: {
      // new moon
      return <WiMoonAltNew {...iconProps} />;
    }
    default: {
      return <WiNa {...iconProps} />;
    }
  }
};
