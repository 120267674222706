import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { format } from 'date-fns';
import { WiThermometer, WiUmbrella } from 'react-icons/wi';
import { useInterval } from 'beautiful-react-hooks';
import { Color, headerHeight, padding } from '../../constants';
import { Logo } from '../Logo';
import { useWeather } from '../../api/weather-context';
import { useSensorData } from '../../api';

enum AirQuality {
  GOOD = 'GOOD',
  MODERATE = 'MODERATE',
  BAD = 'BAD',
}
const presentation = {
  [AirQuality.GOOD]: {
    icon: '/svg/smile-face.svg',
  },

  [AirQuality.MODERATE]: {
    icon: '/svg/neutral-face.svg',
  },
  [AirQuality.BAD]: {
    icon: '/svg/frown-face.svg',
  },
};
const useStyles = createUseStyles({
  header: {
    display: 'flex',
    width: '100%',
    height: headerHeight,
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 99999,
    backgroundColor: Color.white,
    padding,
  },
  logo: {
    position: 'absolute',
    width: `calc(4 * ${headerHeight})`,
    height: headerHeight,
    left: `calc(50% - 2 * ${headerHeight})`,
  },
  date: {
    fontFamily: 'Fira-Sans-Medium',
    fontWeight: 600,
    fontSize: 'clamp(1.5rem, 7vh, 16rem)',
    color: Color.b2bBlueDark,
  },
  weather: {
    display: 'flex',
    fontSize: '3rem',
    color: Color.b2bBlueDark,
  },
  temperature: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginTop: '0.2vw',
    marginBottom: '0.2vw',
    '& svg': {
      marginRight: '0.2vw',
    },
    paddingRight: 32,
  },
  precipitation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginTop: '0.2vw',
    marginBottom: '0.2vw',
    '& svg': {
      marginRight: '0.2vw',
    },
  },
  quality: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginTop: '0.2vw',
    marginBottom: '0.2vw',
    '& svg': {
      marginRight: '0.2vw',
    },
  },
  value: {
    display: 'flex',
    marginBottom: 0,
    fontSize: 'clamp(1.5rem, 7vh, 16rem)',
    fontFamily: 'Fira-Sans-Medium',
    fontWeight: 600,
  },
  unit: {
    display: 'flex',
    paddingLeft: 8,
    fontSize: 'clamp(1.5rem, 3vh, 16rem)',
  },
  icon: {
    display: 'flex',
    alignSelf: 'flex-end',
    marginBottom: 6,
  },
  [AirQuality.GOOD]: {
    color: Color.airQualityGood,
  },
  [AirQuality.MODERATE]: {
    color: Color.airQualityModerate,
  },
  [AirQuality.BAD]: {
    color: Color.airQualityBad,
  },

  image: {
    marginLeft: '2rem',
    height: 'clamp(1.5rem, 3vw, 16rem)',
    width: 'clamp(1.5rem, 3vw, 16rem) !important',
  },
});

type HeaderProps = {
  co2Id: string;
};

export const Header: React.FC<HeaderProps> = ({ co2Id }) => {
  const { data: co2 } = useSensorData(co2Id);
  const airQuality = useMemo(() => {
    const value = Math.min(2000, co2 || 0);
    if (value > 1200) {
      return AirQuality.BAD;
    }
    if (value > 1000) {
      return AirQuality.MODERATE;
    }
    return AirQuality.GOOD;
  }, [co2]);

  const classes = useStyles();
  const weather = useWeather();

  const [time, setTime] = useState(format(new Date(), 'HH:mm'));
  const tickClock = () => setTime(format(new Date(), 'HH:mm'));
  useInterval(tickClock, 1000);

  if (!weather?.data) return null;
  const { data } = weather;
  const { current, daily } = data;

  const temperature = current?.temp || 0;
  const precipitation = daily?.[0].pop || 0;

  return (
    <header className={classNames(classes.header)}>
      <div className={classes.weather}>
        <div className={classes.temperature}>
          <WiThermometer className={classes.icon} />
          <div className={classes.value}>{Math.round(temperature)}</div>
          <div className={classes.unit}>{`°C`}</div>
        </div>
        <div className={classes.precipitation}>
          <WiUmbrella className={classes.icon} />
          <div className={classes.value}>
            {(precipitation * 100).toFixed(0)}
          </div>
          <div className={classes.unit}>{`%`}</div>
        </div>
        <div className={classes.quality}>
          <img
            src={presentation[airQuality].icon}
            alt="quality"
            className={classes.image}
          />
          <div className={classes.value}>{co2}</div>ppm
        </div>
      </div>
      <Logo className={classes.logo} />
      <div className={classes.date}>{time}</div>
    </header>
  );
};
