import React from 'react';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { WiThermometer } from 'react-icons/wi';
import de from 'date-fns/esm/locale/de';
import { WeatherIcon } from '../../components/WeatherIcon';
import { Color, padding } from '../../constants';
import { WeatherDataDaily } from '../../api';
import { Card } from '../../components/Card';

const useStyles = createUseStyles({
  card: {
    display: 'flex',
    marginLeft: padding,
    width: '25%',
    height: '100%',
    padding: '1.5vw',
    background: Color.white,
  },
  cardTitle: {
    fontSize: 'clamp(1.5rem, 2vw, 16rem)',
    textAlign: 'left',
    padding: 10,
  },
  container: {
    display: 'flex',
    height: '100%',
    padding: '1.5vw',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyItems: 'end',
    columnGap: '0.5vw',
  },
  weatherIcon: {
    height: 'clamp(1.2rem, 7vw, 16rem)',
    width: 'clamp(1.2rem, 7vw, 16rem)',
  },
  secondaryIcon: {
    height: 'clamp(2.2rem, 3vw, 16rem)',
    width: 'clamp(2.2rem, 3vw, 16rem)',
    justifySelf: 'end',
  },
});

export interface DailyForecastData extends Pick<WeatherDataDaily, 'dt'> {
  icon: string;
  maxTemperature: number;
  minTemperature: number;
}

type DailyForecastCardProps = {
  dailyForecastData: DailyForecastData;
};

export const DailyForecastCard: React.FC<DailyForecastCardProps> = ({
  dailyForecastData,
}) => {
  const classes = useStyles();
  const { dt, icon, maxTemperature, minTemperature } = dailyForecastData;

  return (
    <Card
      title={`${format(new Date(dt * 1000), 'eeee', { locale: de })}`}
      className={classes.card}
    >
      <div className={classes.container}>
        <WeatherIcon type={icon} className={classes.weatherIcon} />
        <div className={classes.grid}>
          <WiThermometer className={classes.secondaryIcon} />
          {`${Math.round(maxTemperature)} °C`}
          <WiThermometer className={classes.secondaryIcon} />
          {`${Math.round(minTemperature)} °C`}
        </div>
      </div>
    </Card>
  );
};
